<template>
  <b-card
    no-body
  >
    <b-card-body>
      <b-card-title>Permissions</b-card-title>
      <b-card-sub-title>Permission according to roles</b-card-sub-title>
    </b-card-body>
    <b-table
      striped
      responsive
      :items="permissionsData"
      class="mb-0"
    >

      <template #cell(service)="data">
        <img
          width="32"
          class="mr-1"
          :src="require(`@/assets/images/logos/${data.value}.webp`)"
        >
      </template>
      <template #cell()="data">
        <b-form-checkbox
          disabled
          :class="`custom-control-${data.value > 1 ? 'danger' : 'success'}`"
          :checked="data.value > 0"
        />
      </template>

    </b-table>
  </b-card>
</template>

<script>
import {
  BCard, BTable, BCardBody, BCardTitle, BCardSubTitle, BFormCheckbox,
} from 'bootstrap-vue'

export default {
  components: {
    BCard, BTable, BCardBody, BCardTitle, BCardSubTitle, BFormCheckbox,
  },
  setup() {
    const permissionsData = [
      {
        service: 'Gsuite',
        'reset password': 1,
        'manage permissions': 0,
        'invite users': 1,
        'install apps': 2,
        'manage subscription': 1,
      },
      {
        service: 'Asana',
        'reset password': 1,
        'manage permissions': 0,
        'invite users': 1,
        'install apps': 2,
        'manage subscription': 1,
      },
      {
        service: 'Zoom',
        'reset password': 1,
        'manage permissions': 1,
        'invite users': 1,
        'install apps': 2,
        'manage subscription': 1,
      },
      {
        service: 'Github',
        'reset password': 1,
        'manage permissions': 1,
        'invite users': 1,
        'install apps': 2,
        'manage subscription': 1,
      },
    ]

    return {
      permissionsData,
    }
  },
}
</script>

<style>

</style>
