<template>
  <b-card title="User Timeline">
    <app-timeline>

      <!-- 12 INVOICES HAVE BEEN PAID -->
      <app-timeline-item logo="Gsuite">
        <div class="d-flex flex-sm-row flex-column flex-wrap justify-content-between mb-sm-0">
          <h6>Added to Developer Group</h6>
          <small class="text-muted">12 min ago</small>
        </div>
        <p>Permission added by @Eilon.</p>
      </app-timeline-item>
      <app-timeline-item logo="Workday">
        <div class="d-flex flex-sm-row flex-column flex-wrap justify-content-between mb-sm-0">
          <h6>Service Onboarded</h6>
          <small class="text-muted">2 days ago</small>
        </div>
        <p>Permission added by @Eilon.</p>
      </app-timeline-item>
      <app-timeline-item logo="DropBox">
        <div class="d-flex flex-sm-row flex-column flex-wrap justify-content-between mb-sm-0">
          <h6>Service Onboarded</h6>
          <small class="text-muted">4 days ago</small>
        </div>
        <p>Permission added by @Eilon.</p>
      </app-timeline-item>
    </app-timeline>
  </b-card>
</template>

<script>
import {
  BCard,
} from 'bootstrap-vue'
import AppTimeline from '@core/components/app-timeline/AppTimeline.vue'
import AppTimelineItem from '@core/components/app-timeline/AppTimelineItem.vue'

export default {
  components: {
    BCard,
    // BImg,
    // BMedia,
    // BAvatar,
    AppTimeline,
    AppTimelineItem,
  },
}
</script>

<style>

</style>
